import functions from "./functions.js";

$(function () {

    const $form = $('.booking-add-guests');
    const form = $form[0];

    if (!form) {
        return;
    }

    const bookingAddGuests = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            const isRegistration = $form.attr('data-registration');

            $("#customerIsPassenger").on('change', e => {
                let $passenger = $('.passenger:first').closest('.row');
                $passenger.find('[name="firstName0"]').val($('.customer[name="firstName"]').val());
                $passenger.find('[name="lastName0"]').val($('.customer[name="lastName"]').val());
                $passenger.find('[name="email0"]').val($('.customer[name="email"]').val());
                $passenger.find('[name="phone0"]').val($('.customer[name="phone"]').val());
                $passenger.find('[name="address0"]').val($('.customer[name="address"]').val());
                $passenger.find('[name="city0"]').val($('.customer[name="city"]').val());
            });

            // BOOKING PAYMENT TYPE CHECKBOX
            if($('[name="paymentType"]')[0]) {
                checkPaymentType();
                $('[name="paymentType"]').on('change', e => {
                    checkPaymentType();
                });

                function checkPaymentType() {
                    $('[name="paymentType"]').each((index, item) => {
                        const $item = $(item);
                        const id = $item.attr('id');
                        $('[for=' + id + ']').find('[id='  + id + ']').prop('checked', $item.is(':checked'));
                    });
                }
            }

            $('[name="firstName0"]').on('change', e => {
                const $target = $(e.currentTarget);
                $('.holder-name').text($target.val());
            });

            this.$calculation = $(".table-calculation");
            this.$payment = $(".payment-details");
            
            this.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                bookingAddGuests.calc.push(item);
            });

            this.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('.calc-item');
                let $quantity = $row.find('[name=quantity]');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                bookingAddGuests.calculate($(this));
            });

            this.$calculation.find('input[type="checkbox"]').each((index, item) => {
                item.checked && $(item).first().trigger('change');
            });

            $('[name^=departurePlace]').on('change', e => {
                const $target = $(e.currentTarget);
                bookingAddGuests.selectionCalculate($target);
            });

            $('[name^=seat]').on('change', e => {
                const $target = $(e.currentTarget);
                bookingAddGuests.selectionCalculate($target);
            });

            isRegistration && bookingAddGuests.total();

            $form.on('submit', e => {
                e.preventDefault();
                $('button[type="submit"]').attr('disabled', 'disabled');
                bookingAddGuests.submit();
            });

            $('.btn-pay').on('click', e => {
                let $button = $(e.currentTarget);
                let request = {
                    amount : +$button.parent().attr('data-amount'),
                    rId : $("[name='rId']").val(),
                    currencyId : $("[name='currencyId']").val()
                };

                $.post('/services/mars_create_payment_link_for_amount/', request).then(response => {
                    if (response) {
                        window.location.href = response;
                    }
                });
            });

        },
        selectionCalculate($target) {
            const value = $target.val();
            const data = $target.attr('data-data') ? JSON.parse($target.attr('data-data')) : [];

            if(data) {
                let dataIds = [];
                data.forEach(dataItem => {
                    dataIds.push(dataItem.itemUniqueId);
                });
                bookingAddGuests.calc = bookingAddGuests.calc.filter(calcItem => {
                    return ! dataIds.includes(calcItem.id);
                });
            }

            $target.attr('data-calc', value);

            if(value) {
                let item = JSON.parse(value);
                item.inCalc = item.isOptional ? 0 : 1;

                $target.attr('data-id', item.id);

                bookingAddGuests.calc.push(item);

            }

            bookingAddGuests.calculate($target);
        },
        calculate($elem) {
            let $row = $elem.closest('.calc-item');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            checked = $elem.attr('data-calc') ? 1 : checked;
            let id = $checkbox.attr('id');
            id = $elem.attr('data-id') ? $elem.attr('data-id') : id;

            //let $quantity = $row.find('select.quantity:visible');
            //let quantity = null;

            /*if ($quantity.length) {
                quantity = +$quantity.val();
            }*/

            $.each(bookingAddGuests.calc, function (key, calc) {

                if (calc.id == id) {

                    /*if (quantity == null) {
                        quantity = bookingAddGuests.calc[key].quantity;
                    }*/

                    bookingAddGuests.calc[key].inCalc = checked;
                    //bookingAddGuests.calc[key].quantity = (quantity || 1);
                    bookingAddGuests.calc[key].quantity = 1;
                    bookingAddGuests.calc[key].total = bookingAddGuests.itemTotal(bookingAddGuests.calc[key]);
                    //bookingAddGuests.calc[key].totalConverted = bookingAddGuests.itemTotalConverted(bookingAddGuests.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(bookingAddGuests.calc[key].total, 2, ',', '.'));
                    //$row.find('.item-total-amount-converted').html(functions.formatMoney(bookingAddGuests.calc[key].totalConverted, 2, ',', '.'));
                }
            });


            bookingAddGuests.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;
            item.quantity = 1;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek', 'Once']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        total() {
            bookingAddGuests.totalAmount = 0.00;
            bookingAddGuests.totalAmountConverted = 0.00;

            $.each(bookingAddGuests.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    bookingAddGuests.totalAmount += bookingAddGuests.itemTotal(calc);
                    //bookingAddGuests.totalAmountConverted += bookingAddGuests.itemTotalConverted(calc);
                }
            });

            $('.total-amount').html(functions.formatMoney(bookingAddGuests.totalAmount, 2, ',', '.'));

            let confirmationPayment = $('[data-confirmationPayment]').attr('data-confirmationpayment') ? $('[data-confirmationPayment]').attr('data-confirmationpayment') : 0;
            const confirmationPercent = $('[data-confirmationPercent]').attr('data-confirmationpercent') ? $('[data-confirmationPercent]').attr('data-confirmationpercent') : null;

            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');
            let advance = advancePaymentPercent ? bookingAddGuests.totalAmount * (advancePaymentPercent / 100) : null;

            confirmationPayment = confirmationPercent ? bookingAddGuests.totalAmount * (confirmationPercent / 100) : confirmationPayment;
            if(confirmationPayment) {
                advance = advancePaymentPercent ? (bookingAddGuests.totalAmount - confirmationPayment) * (advancePaymentPercent / 100) : null;
            }

            if(! advance) {
                const advanceConfirmationAmount = $('[data-advanceConfirmationAmount]').attr('data-advanceconfirmationamount');
                advance = advanceConfirmationAmount;
            }

            const rest = (bookingAddGuests.totalAmount - confirmationPayment) - advance;

            let passedConfirmation = $('.advance').attr('data-confirmationpayment');
            if(passedConfirmation) advance = (+advance) + (+passedConfirmation);

            $('.confirmationPayment').html(functions.formatMoney(confirmationPayment, 2, ',', '.'));
            $('.advance').html(functions.formatMoney(advance, 2, ',', '.'));
            $('.rest').html(functions.formatMoney(rest, 2, ',', '.'));
        },
        submit() {

            let data = {
                customer: {},
                passengers: [],
                paymentType: null,
                calc: null,
                note: null,
            };
            $(".customer").each(function( index ) {
                data.customer[$(this).attr('name')] = $(this).val();
            });

            $(".passenger").each(function( index ) {
                let $input = $(this).attr('name');
                let idx = $input.replace(/\D/g,'');
                let name = $input.replace(/[0-9]/g, '');

                if ( ! data.passengers[idx]) data.passengers[idx] = {};
                data.passengers[idx][name] = $(this).val();
            });

            $('[name^="placeFromId"]').each(function( index ) {
                let inputName = $(this).attr('name');
                let idx = inputName.replace(/\D/g,'');

                if ( ! data.placeFrom) data.placeFrom = {};
                data.placeFrom[idx] = $(this).val();
            });

            data.rId = $("[name='rId']").val();
            data.rhid = $("[name='rhid']").val();

            const $note = $form.find('[name="note"]');
            let noteValue = $note.val();

            const $school0 = $form.find('[name="school0"]');
            const school0Value = $school0.val();

            const $discover0 = $form.find('[name="discover0"]');
            const discover0Value = $discover0.val();

            let notePrefix = '';
            notePrefix = (school0Value ? 'Fakultet / škola: ' + school0Value + '; ' : notePrefix);
            notePrefix = discover0Value ? (notePrefix + 'Za putovanje sam saznao/la preko: ' + discover0Value + '; ') : notePrefix;

            noteValue = notePrefix ? notePrefix + 'Napomena: ' + noteValue : noteValue;
            data.note = noteValue;

            $.each(bookingAddGuests.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete bookingAddGuests.calc[key];
                }
            });

            data.paymentType = this.$payment.find("[name='paymentType']:checked").val() ? this.$payment.find("[name='paymentType']:checked").val() : 'bankTransfer';
            data.calc = JSON.stringify(bookingAddGuests.calc);

            /*if ($("[name='agreeContact']").attr('checked')) {
                data.note = 'Putnici se slažu da se kontakt podaci koriste za marketinške svrhe';
            }*/

            if(! data.customer.firstName) {
                data.customer.firstName = data.passengers[0].firstName;
                data.customer.lastName = data.passengers[0].lastName;
            }

            if(! data.customer.city && data.passengers[0].city) {
                data.customer.city = data.passengers[0].city;
            }

            $.post('/services/booking_add_guests/', data).then(response => {

                response = JSON.parse(response);

                if(response.status == true && response.url) {
                    location = response.url;
                } else {
                    $('#bookingAddGuests').modal('show');
                    $(".response-output-success").toggleClass('d-none', !response.status);
                    $(".response-output-error").toggleClass('d-none', response.status);
    
                    $('.contact-btn').removeClass('d-none');
                    $('.contact-spinner').removeClass('d-flex');
                    $('.contact-spinner').addClass('d-none');
    
                    setTimeout(() => {
                        $('#bookingAddGuests').modal('hide');
                        //$('button[type="submit"]').attr('disabled', false);
                    }, 4000);
    
                    if (response.status == true) {
                        form.reset();
                    }
                }

            });

        },
    }

    bookingAddGuests.init();
});